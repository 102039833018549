<template>
  <div>
    <b-button variant="primary" v-b-modal.modal-multiselect class="mr-2">Search by Category</b-button>
    <b-button variant="outline-danger" @click="clear">
      <feather-icon icon="DeleteIcon" class="text-muted" stroke="red" />
      <!-- Clear -->
    </b-button>

    <!-- Modal -->
    <div>
      <b-modal v-model="myModal" id="modal-multiselect" centered size="md" header-class="text-uppercase" scrollable
        hide-footer>
        <template #modal-header>
          <!-- Emulate built in modal header close button action -->
          <div class="
              w-100
              d-flex
              justify-content-between
              align-items-center
              text-uppercase
            ">
            <h5 class="mb-0">Search by {{ urlName }}</h5>
            <b-button variant="outline-danger" @click="myModal = false"> X </b-button>
          </div>
        </template>

        <form ref="form" @submit.prevent="handleSubmit" class="px-2">

          <b-form-checkbox v-model="allSelected" aria-describedby="categories" aria-controls="categories"
            @change="toggleAll" class="selectAll">
            {{ allSelected ? 'Un-select All' : 'Select All' }}
          </b-form-checkbox>

          <b-form-checkbox-group v-model="selectedTags" buttons class="row flex-wrap align-items-center pt-2 pb-3">
            <b-form-checkbox v-for="f in filters" :key="f.id" :value="f.id" size="sm" class="rounded-pill mb-2 mr-2 tag"
              button button-variant="outline-primary">
              {{ f.name }}
            </b-form-checkbox>
          </b-form-checkbox-group>
          <div class="w-100 text-center">
            <b-button variant="primary" type="submit" class="col-6" :disabled="selectedTags.length === 0">
              <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
              <span v-else>Submit</span>
            </b-button>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>
    
<script>
import store from "@/store";
import { useRouter } from "@core/utils/utils";
import { ref, reactive, computed, watch } from "@vue/composition-api";
import changeArrtoJson from "../functions/changeArrtoJson";
import {
  BButton,
  BSpinner,
  BRow,
  BCol,
  BFormSelect,
  BFormSelectOption,
  BModal,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BSpinner,
    BRow,
    BCol,
    BFormSelect,
    BFormSelectOption,
    BModal,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
    urlName: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const { router } = useRouter();
    const myModal = ref(false);
    const spinner = ref(false);
    const allSelected = ref(false);
    const selectedTags = ref([]);

    const handleSubmit = () => {
      emit("getFilteredCategories", selectedTags.value);
    }

    const toggleAll = () => {
      allSelected.value ?
        selectedTags.value = props.filters.map(f => {
          return f.id;
        }) :
        selectedTags.value = [];
    }

    // Close Function
    const clear = () => {
      router.push(`/my-pages/${props.urlName}?page=1`);
    };
    return {
      myModal,
      allSelected,
      spinner,
      selectedTags,
      toggleAll,
      handleSubmit,
      clear,
    }
  },
};
</script>
<style scoped>
.tag {
  font-size: 1rem !important;
  flex: 0 0 auto !important;
  padding: 0.6rem 1.2rem;
}
</style>