<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div>
    <b-card no-body class="mb-0">
      <!-- Loading -->
      <template v-if="spinner === true">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <div class="text-center m-5">
              <b-spinner variant="primary" label="Text Centered"></b-spinner>
            </div>
          </div>
        </div>
      </template>

      <b-card v-else no-body class="mb-0 card-company-table">
        <!-- Search -->
        <div class="m-2">
          <b-row>
            <b-col md="4">
              <FSearchInput
                urlName="services"
                :fetchDatas="fetchServiceDatas"
              />
            </b-col>
            <b-col md="4">
              <FCategoriesCom
                urlName="services"
                @getServiceCategories="getCategories"
                :isService="true"
              />
            </b-col>
            <b-col md="4">
              <FStatesCom urlName="services" @getStates="getStates" />
            </b-col>
            <!-- <b-col md="4">
                <FUser urlName="services" @getUsers="getUsers" />
              </b-col> -->

            <b-col
              md="12"
              class="d-flex align-items-center justify-content-end"
            >
              <div>
                <router-link
                  :to="{
                    name: 'add-new',
                    query: {
                      categories: JSON.stringify(categories),
                      states: JSON.stringify(states),
                      users: JSON.stringify(users),
                      isService: true,
                      isEvent: false,
                      isNews: false,
                      isEdit: false,
                    },
                  }"
                  class="d-block"
                >
                  <b-button variant="primary">
                    <feather-icon icon="PlusIcon" class="mr-50" />
                    <span class="text-nowrap">Add New Service</span>
                  </b-button>
                </router-link>
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- Services Table -->
        <Table
          :items="serviceDatas"
          :fields="fields"
       
          routeName="service-details"
          :fetchDatas="fetchServiceDatas"
          pageName="services"
          :categories="categories"
          :states="states"
          :users="users"
        />
      </b-card>
    </b-card>
  </div>
</template>
  
  <script>
import store from "@/store";
import Table from "../components/Table.vue";
import FCategoriesCom from "../components/FCategoriesCom.vue";
import FStatesCom from "../components/FStatesCom.vue";
import FSearchInput from "../components/FSearchInput.vue";
import FUser from "../components/FUser.vue";
import router from "@/router";
import { useRouter } from "@core/utils/utils";
import { ref, reactive, computed, watch } from "@vue/composition-api";
import {
  BCard,
  BButton,
  BSpinner,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BModal,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BFormCheckbox,
  BFormTimepicker,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BModal,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormCheckbox,
    BFormTimepicker,
    Table,
    FCategoriesCom,
    FStatesCom,
    FSearchInput,
    FUser,
  },
  setup(props, { emit }) {
    const { route, router } = useRouter();
    const fields = [
      { key: "id", label: "#" },
      { key: "title", label: "title" },
      { key: "sub_title", label: "sub title" },
      { key: "category.name", label: "category name" },
      { key: "actions", label: "actions" },
    ];
    const spinner = ref(false);
    const serviceDatas = ref([]);
    const categories = ref([]);
    const users = ref([]);
    const states = ref([]);
    const pageNum = ref(1);
    const userData = JSON.parse(localStorage.getItem("userData"));

    //Datas Fetching
    const fetchServiceDatas = () => {
      // spinner.value = true;
      store
        .dispatch(
          "app-mypages/fetchDatas",
          `services?merchant?user_id=${userData.id}`
        )
        .then((response) => {
          serviceDatas.value = response.data.data;
          // spinner.value = false;
        });
    };
    fetchServiceDatas();
    const fetchUsers = () => {
      spinner.value = true;
      store.dispatch("app-mypages/fetchDatas", "users").then((response) => {
        users.value = response.data.data;
        spinner.value = false;
      });
    };
    fetchUsers();
    // Route Params
    const routeParams = computed(() => route.value.params);
    watch(routeParams, () => {
      fetchServiceDatas();
    });
    const getCategories = (fetchedCategories) => {
      categories.value = fetchedCategories;
    };
    const getStates = (fetchedStates) => {
      states.value = fetchedStates;
    };
    // const getUsers = (fetchedUsers) => {
    //   users.value = fetchedUsers;
    // };

    return {
      fields,
      spinner,
      serviceDatas,
      getCategories,
      users,
      categories,
      states,
      getStates,
      fetchServiceDatas,
    };
  },
};
</script>
  