<template>
  <div>
    <!-- F Categories -->
    <form @submit.prevent="fetchSearchDatas">
      <label for="search_type" class="font-weight-bolder">Search by Typing :</label>
      <div class="d-flex align-content-center justify-content-between w-100">
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" class="text-muted" />
          </b-input-group-prepend>
          <b-form-input v-model="searchQuery" :value="searchQuery" placeholder="Search Service" />
        </b-input-group>
        <b-button variant="primary" type="submit" class="mr-1">
          <feather-icon icon="SearchIcon" class="text-muted" stroke="white" />
        </b-button>
        <b-button variant="outline-danger" type="button" @click="clear">
          <feather-icon icon="DeleteIcon" class="text-muted" stroke="red" />
        </b-button>
      </div>
    </form>
  </div>
</template>
      
<script>
import store from "@/store";
import router from "@/router";
import { useRouter } from "@core/utils/utils";
import { ref, reactive, computed, watch } from "@vue/composition-api";
import {
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
  },
  props: {
    urlName: {
      type: String,
      default: "",
    },
    fetchDatas: {
      type: Function,
    },
  },
  setup(props, { emit }) {
    const { route, router } = useRouter();

    // Search Query
    const searchQuery = ref("")

    //Datas Fetching
    const fetchSearchDatas = () => {
      console.log(searchQuery.value)
      if (searchQuery.value == "") {
        router.push(`/my-pages/${props.urlName}?page=1`);
      } else {
        router.push(`/my-pages/${props.urlName}?page=1&search=${searchQuery.value}`);
      }
    };

    const clear = () => {
      searchQuery.value = ""
      router.push(`/my-pages/${props.urlName}?page=1`)
    };

    return {
      searchQuery,
      fetchSearchDatas,
      clear
    };
  },
};
</script>