<template>
    <div>
      <!-- F States -->
      <div class="mb-2">
        <label for="categories" class="font-weight-bolder"
          >Search by User :</label
        >
        <b-form-select v-model="filter_user_id" @change="fetchFilterUserDatas">
          <b-form-select-option disabled value=""
            >Search by User</b-form-select-option
          >
          <b-form-select-option
            v-for="u in users"
            :key="u.id"
            :value="u.id"
            >{{ u.name }}</b-form-select-option
          >
        </b-form-select>
      </div>
    </div>
  </template>
        
      <script>
  import store from "@/store";
  import router from "@/router";
  import { useRouter } from "@core/utils/utils";
  import { ref, reactive, computed, watch } from "@vue/composition-api";
  import {
    BButton,
    BSpinner,
    BRow,
    BCol,
    BFormSelect,
    BFormSelectOption,
  } from "bootstrap-vue";
  
  export default {
    components: {
      BButton,
      BSpinner,
      BRow,
      BCol,
      BFormSelect,
      BFormSelectOption,
    },
    props: {
      urlName: {
        type: String,
        default: "",
      },
    },
    setup(props, { emit }) {
      const { route, router } = useRouter();
      const filter_user_id = ref("");
      const users = ref([]);
  
      //Datas Fetching
      const fetchFilterUserDatas = () => {
        router.push(
          `/my-pages/${props.urlName}/merchant?user_id=${filter_user_id.value}`
        );
      };
  
      const fetchUsers = () => {
        store.dispatch("app-mypages/fetchDatas", "users").then((response) => {
          users.value = response.data.data;
          emit("getUsers", users.value);
        });
      };
      fetchUsers();
  
      return {
        filter_user_id,
        fetchFilterUserDatas,
        users,
      };
    },
  };
  </script>